.background {
    background: rgb(25, 25, 25);
    color: #ffffff;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    padding: 0 !important;
    z-index: -1 !important;
    background: linear-gradient(225deg, rgba(25, 25, 25, 1) 0%, rgba(0, 0, 0, 1) 100%);
    height: 115px;
    /* background: linear-gradient(180deg, rgba(25, 25, 25, 1) 50%, rgba(0, 0, 0, 0) 100%);
    height: 500px; */
}

.header-stats {
    position: relative;
    z-index: 99;
    margin-top: -39px;
    line-height: 19.2px;
    color: white;
    text-align: right;
    padding: 20px 20px 0px 20px;
}

main.Content {
    top: 0;
    margin-top: 35px;
    padding: 15px;
    text-align: left;
}
