.Logo img {
    max-width: 220px;
    height: 25px !important;    
}

img {
    /* vertical-align: middle; */
    border-style: none;
    margin-right: 0 !important;
}

.mobile-show {
    display: none;
}

@media (max-width: 576px) {
    .mobile-hide {
        display: none !important;
    }
    .mobile-show {
        display: initial !important;
    }
    .Logo img {
        max-width: 220px;
        height: 25px;
    }
}
